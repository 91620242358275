export enum Action {
  ADD_LIVE_USER = "ADD_LIVE_USER",
  UPDATE_HISTORICAL_USERS = "UPDATE_HISTORICAL_USERS",
  SET_USER_LOCATION = "UPDATE_USER_LOCATION",
  SET_LOADING = "SET_LOADING",
  SET_ALERT = "SET_ALERT",
  TOGGLE_HEATMAP = "TOGGLE_HEATMAP",
  SET_APP_USER_LOCATION = "SET_APP_USER_LOCATION",
  SET_WEBSOCKET_CONNECTION_STATUS = "SET_WEBSOCKET_CONNECTION_STATUS",
}
